import { createRouter, createWebHistory } from 'vue-router'
import gsap from 'gsap'
import { onMounted, nextTick } from 'vue'

import Top from '@/components/container/Top/index.vue'
import Contact from '@/components/container/Top/Contact.vue'
import No1 from '@/components/container/Trainer/No1.vue'
import News from '@/components/container/News.vue'
import Facilittes from '@/components/container/Facilittes.vue'
import NewsLists from '@/components/container/NewsLists.vue'
import { NewsList } from '@/models/news'
import { reactive } from 'vue' //リアクティブメソッドを呼び出し
import { useApplicationStore } from '@/store/modules/applicationModule'

const routes = [
  {
    name: 'Top',
    path: '/',
    component: Top,
  },
  {
    name: 'Contact',
    path: '/Contact',
    component: Contact,
  },
  {
    name: 'No1',
    path: '/No1',
    component: No1,
  },
  {
    name: 'News',
    path: '/News',
    component: News,
    props: { products: NewsList },
  },
  {
    name: 'Facilittes',
    path: '/Facilittes',
    component: Facilittes,
  },
  {
    name: 'NewsLists',
    path: '/NewsLists',
    component: NewsLists,
    props: { products: NewsList },
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior: async (to, from, savedPosition) => {
    const ApplicationStore = useApplicationStore()
    ApplicationStore.UPD_TOP_PANEL4(false)
    ApplicationStore.UPD_TOP_PANEL5(false)
    if (savedPosition) {
      // console.log('savedPosition')
      // console.log(savedPosition)
      sessionStorage.setItem('positionY', savedPosition.top)
      return savedPosition
    } else if (to.hash) {
      await nextTick()
      // console.log('to.hash')
      // console.log(to.hash)
      return { el: to.hash, top: 100, behavior: 'smooth' }
    } else {
      sessionStorage.setItem('positionY', 0)
      return { top: 0 }
    }
  },
})

// router.beforeEach((to, from, next) => {
//   const tl = gsap.timeline()
//   tl.to('.wrapper', {
//     duration: 0.5,
//     opacity: 0,
//     x: 2000,
//     onStart: () => {
//       const target = document.getElementsByClassName('wrapper')
//       target[0].classList.remove('active')
//     },
//     onComplete: () => {
//       const target = document.getElementsByClassName('wrapper')
//       target[0].classList.add('active')
//       next()
//     },
//   }).to(
//     '.wrapper',
//     {
//       duration: 0.5,
//       opacity: 1,
//       x: 0,
//     },
//     1
//   )
// })

export default router
